// VGem WELDEN Farben

// BLAU
$dunkelblau: rgb(10,33,97);
$dunkelblaugrau: #A1ADC7;

$blau660: rgb(66,107,186);
$blau659: rgb(120,150,207);
$blau658: rgb(171,186,222);
$blau657: rgb(199,209,227);
$blau656: rgb(219,222,230);

// SCHWARZ
$schwarz: #222;

// WEISS
$weiss: #fff;

//GRAU
$hellgrau: #eee;
$mittelgrau: #999;
$dunkelgrau: #555;

// GELB
$dotter: rgb(252,189,48);
$dotter40: rgb(252,229,172);
$dotter20: rgb(254,242,214);

$hellgelb: #F0EBB0;

// ROTBRAU
$schoko: #8F5C30;
$schokino: #B08057;

// TRANSPARENT 
$shadow-color: rgba(000,000,000,0.5);
$milchglas: rgba(255,255,255,0.8);
$grauglas: rgba($hellgrau,0.5);

// TEXT-SHADOW
$default-text-shadow-color: rgba($schwarz, 0.5);
$default-text-shadow-h-offset: 0px;
$default-text-shadow-v-offset: 0px;
$default-text-shadow-blur: 20px;
$default-text-shadow-spread: false;

// BODY DEFAULT
$default-bg-color: $weiss;
$default-text-color: $schwarz;

// HEADER DEFAULT
$header-bg-color: $blau660;

// LINK DEFAULT
$default-a-link-color: $schoko;
$default-v-link-color: $schokino;
$default-h-link-color: $dunkelblau;

// FOOTER
$footer-bg-color: $dunkelblau;
$footer-text-color: $weiss;
$footer-a-color: $schokino;

// MENU
$menu-bg-color: $blau660;
$menu-a-color: $dunkelblau;
$menu-sub-bg-color: $dotter20;
$menu-sub-a-color: $dunkelblau;
