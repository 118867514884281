// 
/* Embed main font family UBUNTU as Open Webfont */

@font-face {
font-family: 'Ubuntu';
font-style: normal;
font-weight: 300;
src: local('Ubuntu Light'), local('Ubuntu-Light'), url('http://themes.googleusercontent.com/static/fonts/ubuntu/v4/_aijTyevf54tkVDLy-dlnLO3LdcAZYWl9Si6vvxL-qU.woff') format('woff');
}
@font-face {
font-family: 'Ubuntu';
font-style: italic;
font-weight: 300;
src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'), url('http://themes.googleusercontent.com/static/fonts/ubuntu/v4/DZ_YjBPqZ88vcZCcIXm6VrrIa-7acMAeDBVuclsi6Gc.woff') format('woff');
}
@font-face {
font-family: 'Ubuntu';
font-style: normal;
font-weight: bold;
src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url('http://themes.googleusercontent.com/static/fonts/ubuntu/v4/0ihfXUL2emPh0ROJezvraLO3LdcAZYWl9Si6vvxL-qU.woff') format('woff');
}
