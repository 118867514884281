/* Print Style www.ortsentwicklung.markt-welden.de */

/* Open Webfont einbinden */
// MARKT WELDEN SCHRIFTEN
@import "fonts";
@import "color";

body {
font:normal normal 1em/1.4em;
font-family:Ubuntu, 'Arial Narrow', arial, geneva, helvetica, no-serif;
}
#header,
div.slide,
div.wrap-asides,
aside.search,
#menu,
#menu-sub,
#logos,
#contact,
#footer {
display:none;
height:0;
width:0;
}
#content {
position:static;
color:#000;
}
a {
text-decoration:none;
color:$schoko;
}
body {
background:#fff;
}